export default ({
  state: {
    authSmsConfirm: null,
  },
  getters: {
    authSmsConfirmed: s => s.authSmsConfirm,
  },
  mutations: {
    updateAuthSmsConfirmed(state, status) {
      state.authSmsConfirm = status
    },
  },
  actions: {
    async auth(ctx, userData) {
      this.$axios.defaults.headers.common.Authorization = null
      const fd = new FormData();
      for (let data in userData) {
        fd.append(data, userData[data])
      }

      return await this.$axios({
        method: 'POST',
        url: '/api/auth/login',
        data: fd,
      })
        .then(async (response) => {
          const result = response.data;
          localStorage.setItem('token', result.access_token);
          localStorage.setItem('refresh', result.refresh);
          this.$axios.defaults.headers.common.Authorization = `Bearer ${result.access_token}`;
          await ctx.dispatch('getSession')
          return true
        })
        .catch((error) => {
          if(error.response.data && error.response.data.errors && error.response.data.errors.login) {
            // ctx.commit('updateInputError','Пользователь не найден')
            ctx.dispatch('fetchAlerts', {alerts: 'Неверный email или пароль', type: 'error'})
          } else {
            ctx.dispatch('fetchAlerts', {alerts: 'Произошла ошибка', type: 'error'})
          }
         
          return false
        });
    },
    async OAuth(ctx, token) {
      this.$axios.defaults.headers.common.Authorization = null
      const fd = new FormData();
      
        fd.append('token', token)

      return await this.$axios({
        method: 'POST',
        url: '/api/auth/oauth',
        data: fd,
      })
        .then(async (response) => {
          const result = response.data;
          localStorage.setItem('token', result.access_token);
          localStorage.setItem('refresh', result.refresh);
          this.$axios.defaults.headers.common.Authorization = `Bearer ${result.access_token}`;
          await ctx.dispatch('getSession')
          return true
        })
        .catch((error) => {
            ctx.dispatch('fetchAlerts', {alerts: 'Произошла ошибка', type: 'error'})
          return false
        });
    },
    async logout(ctx) {
      await this.$axios({
        method: 'POST',
        url: '/api/auth/logout',
      })
      .then(async (response) => {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        delete this.$axios.defaults.headers.common.Authorization
        ctx.commit('updateUser', {})
      })
    },
    authPhone(ctx, phone) {
      const fd = new FormData();
      fd.append('phone', `7${phone}`);

      return this.$axios({
        method: 'POST',
        url: '/api/auth/login/phone/send/',
        data: fd,
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }
          return true
        })
        .catch((error) => {
          if(error && error.response && error.response.data && error.response.data.errors && error.response.data.errors.phone) {
            // ctx.commit('updateInputError','Пользователь не найден')
            ctx.dispatch('fetchAlerts', {alerts: 'Пользователя с таким номером телефона не существует', type: 'error'})
          } else if(error && error.response && error.response.data && error.response.data.requestError) {
            // ctx.commit('updateInputError','Пользователь не найден')
            ctx.dispatch('fetchAlerts', {alerts: 'Слишком много запросов на отправку СМС-кода, попробуйте позже', type: 'error'})
          } else {
            ctx.dispatch('fetchAlerts', {alerts: 'Произошла ошибка', type: 'error'})
          }
          console.error(error);
          return false
        });
    },
    authPhoneResend(ctx) {
      return this.$axios({
        method: 'POST',
        url: '/api/auth/login/phone/resend/',
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }
          return true
        })
        .catch((error) => {
          ctx.dispatch('fetchAlerts', {alerts: 'Произошла ошибка', type: 'error'})
          console.error(error);
          return false
        });
    },
    authPhoneCode(ctx, code) {
      const fd = new FormData();
      fd.append('pin', code);

      return this.$axios({
        method: 'POST',
        url: '/api/auth/login/phone/check/',
        data: fd,
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            ctx.commit('updateAuthSmsConfirmed', false)
            return false
          }
          ctx.commit('updateAuthSmsConfirmed', true);
          const result = response.data;
          localStorage.setItem('token', result.access_token);
          localStorage.setItem('refresh', result.refresh);
          this.$axios.defaults.headers.common.Authorization = `Bearer ${result.access_token}`;
          ctx.dispatch('getSession')
          return true
        })
        .catch((error) => {
          ctx.dispatch('fetchAlerts', {alerts: 'Произошла ошибка', type: 'error'})
          console.error(error);
          ctx.commit('updateAuthSmsConfirmed', false)
          return false
        });
    },
  },
})