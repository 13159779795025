<template>
  <div class="register-form" :key="step">
    <form ref="registerForm" action="javascript:void(0)">
      <div class="register-step" v-if="step === 1">
        <div class="register-step__title">Ваш email</div>
        <div class="register-step__description">
          Отправим на него письмо с кодом подтверждения
        </div>
        <TextField
          :type="'email'"
          v-model="email"
          :label="'Email*'"
          :rules="[
            Rules.isRequired(
              email,
              'Для регистрации нужно заполнить Email адрес'
            ),
            Rules.isCorrectEmail(email, 'Некорректный email'),
            !emailAlreadyExists || $route.name === 'TgRegister'
              ? ''
              : 'Этот аккаунт зарегистрирован, хотите <a href=/login>войти?</a>',
          ]"
          class="mb-8"
        />
        <Checkbox
          class="mb-4"
          :label="`Я даю свое согласие на <a href='#openPolicy'>обработку персональных данных</a>*`"
          :rules="[
            Rules.isRequired(
              personalData,
              'Для регистрации нужно согласие на обработку персональных данных'
            ),
          ]"
          v-model="personalData"
        />
        <Checkbox
          class="mb-4"
          :label="`Я подтверждаю, что являюсь сотрудником здравоохранения*`"
          :type="'checkbox'"
          :rules="[
            Rules.isRequired(
              iAmMedic,
              'Для регистрации нужно подтверждение, что вы работник сферы здравоохранения'
            ),
          ]"
          v-model="iAmMedic"
        />
        <Checkbox
          :label="`Я хочу получать рассылку с информацией и полезными материалами на Email`"
          :type="'checkbox'"
          v-model="subscribeEmail"
        />
        <div class="d-flex align-center">
          <div
            class="register-step__button button_pink button"
            @click="emailVerify"
            :class="{ button_disabled: emailSended === 'wait' }"
          >
            Продолжить
          </div>
          <Preloader class="mt-10 ml-4" v-if="emailSended === 'wait'" />
        </div>
      </div>
      <div class="register-step" v-if="step === 2">
        <div class="register-step__title">Ваш email</div>
        <div class="register-step__description">
          Отправили письмо с кодом на {{ email }}.
          <span class="register-step__description_pink" @click="changeEmail"
            >Изменить email?</span
          >
        </div>
        <TextField
          :type="'tel'"
          v-model="letterCode"
          :maskString="'####'"
          :label="'Код из письма*'"
          :readonly="codeConfirmed"
          :rules="[
            Rules.isRequired(letterCode, 'Неправильный код'),
            codeConfirmed !== false || 'Неправильный код',
          ]"
          :successState="codeConfirmed"
        >
          <GetCode
            v-if="!codeConfirmed"
            :refresh="checkEmail"
            :args="[email]"
            class="ml-4 ml-xs-0 mt-xs-4"
          />
        </TextField>
        <div class="d-flex align-center">
          <div
            :class="{ button_disabled: !codeConfirmed }"
            class="register-step__button button_pink button"
            @click="validate(Object.keys(snaplogicData).length ? 5 : 3)"
          >
            <span>Продолжить</span>
          </div>
          <Preloader
            class="mt-10 ml-4"
            v-if="codeConfirmed === null && letterCode.length > 3"
          />
        </div>
      </div>
      <div class="register-step" v-if="step === 3">
        <div class="register-step__title">Личные данные</div>
        <TextField
          :type="'text'"
          v-model="lastName"
          :label="'Фамилия*'"
          :rules="[Rules.isRequired(lastName, 'Укажите вашу фамилию')]"
          class="mb-8 mt-8"
        />
        <TextField
          :type="'text'"
          v-model="name"
          :label="'Имя*'"
          :rules="[Rules.isRequired(name, 'Укажите ваше имя')]"
          class="mb-8"
        />
        <TextField
          :type="'text'"
          v-model="secondName"
          :label="'Отчество*'"
          :rules="[Rules.isRequired(secondName, 'Укажите ваше отчество')]"
          class="mb-8"
        />
        <Select
          v-model="speciality"
          :items="specialties"
          :searchable="true"
          :label="'Специальность*'"
          :placeholder="'Выбрать специальность из списка'"
          :rules="[
            Rules.isRequired(speciality.length > 0, 'Выберите специальность'),
          ]"
        />
        <div
          class="register-step__button button_pink button"
          @click="validate(4)"
        >
          Продолжить
        </div>
      </div>
      <div class="register-step" v-if="step === 4">
        <div class="register-step__title">Основное место работы</div>
        <TextField
          :type="'text'"
          v-model="institution"
          :label="'Учреждение*'"
          :rules="[Rules.isRequired(institution, 'Укажите учреждение')]"
          class="mb-8 mt-8 mb-xs-4"
        />
        <TextField
          type="address"
          v-model="address"
          :label="'Адрес*'"
          :rules="[
            Rules.isRequired(
              address,
              'Выберите адрес учреждения из выпадающего списка'
            ),
          ]"
          class="mb-8 mb-xs-4"
        />
        <TextField
          :type="'text'"
          v-model="department"
          :label="'Отделение'"
          class="mb-8 mb-xs-4"
          :disabled="isNotDepartment === true"
          :rules="[
            Rules.isRequired(
              department,
              'Укажите отделение или нажмите «Нет отделения»'
            ),
          ]"
        >
          <Checkbox
            :label="`Нет отделения`"
            :type="'checkbox'"
            class="ml-5 ml-xs-0 mt-xs-4"
            v-model="isNotDepartment"
          />
        </TextField>

        <div class="buttons-container">
          <div
            class="register-step__button button_empty-pink back button"
            @click="step = 3"
          >
            Назад
          </div>
          <div
            class="register-step__button button_pink button"
            @click="validate(5)"
          >
            Продолжить
          </div>
        </div>
      </div>
      <div class="register-step" v-if="step === 5">
        <div class="register-step__title">
          {{
            Object.keys(snaplogicData).length
              ? `Здравствуйте, ${name}`
              : "Номер телефона"
          }}
        </div>
        <div class="register-step__description">
          Укажите телефон, чтобы быстро входить на портал по смс-коду.
          <br />
          Можно добавить сейчас или позже в личном кабинете.
        </div>
        <TextField
          :type="'tel'"
          :maskString="'+7 (###) ###-##-##'"
          :placeholder="'+7(---) --- -- --'"
          v-model="phoneNumber"
          :label="'Номер телефона'"
          class="mb-8 mb-xs-4"
          :disabled="noPhone === true"
          :rules="[
            Rules.isRequired(phoneNumber, 'Некорректный номер'),
            Rules.isCorrectPhone(phoneNumber, 'Некорректный номер'),
            !phoneAlreadyExists ||
              'Этот аккаунт зарегистрирован, хотите <a href=/login>войти?</a>',
          ]"
        >
          <Checkbox
            :label="`Я отказываюсь использовать номер`"
            :type="'checkbox'"
            class="ml-5 ml-xs-0 mt-xs-4"
            v-model="noPhone"
          />
        </TextField>

        <Checkbox
          :label="`Я хочу получать рассылку с информацией и полезными материалами по СМС`"
          :type="'checkbox'"
          :disabled="noPhone === true"
          v-model="subscribePhone"
          class="mb-8"
        />

        <div class="buttons-container">
          <div
            v-if="!Object.keys(snaplogicData).length"
            class="register-step__button button_empty-pink back button"
            @click="step--"
          >
            Назад
          </div>
          <div
            class="register-step__button button_pink button"
            @click="
              noPhone ? validate(7) : validate(6, checkPhone, phoneNumber)
            "
          >
            Продолжить
          </div>
        </div>
      </div>
      <div class="register-step" v-if="step === 6">
        <div class="register-step__title">Подтверждение номера телефона</div>
        <div class="register-step__description">
          Отправили смс с кодом на номер +7 {{ phoneNumber }}.
          <span class="register-step__description_pink" @click="changePhone"
            >Изменить номер?</span
          >
        </div>
        <TextField
          :type="'tel'"
          v-model="smsCode"
          :maskString="'####'"
          :readonly="smsConfirmed"
          :label="'Код из смс*'"
          :rules="[
            Rules.isRequired(smsCode, 'Неправильный код'),
            smsConfirmed !== false || 'Неправильный код',
          ]"
          :successState="smsConfirmed"
        >
          <GetCode
            v-if="!smsConfirmed"
            :refresh="checkPhoneResend"
            class="ml-4 ml-xs-0 mt-xs-4"
          />
        </TextField>

        <div
          class="register-step__button button_pink button"
          @click="validate(7)"
        >
          Продолжить
        </div>
      </div>
      <div class="register-step" v-if="step === 7">
        <div class="register-step__title">Придумайте пароль</div>
        <div class="register-step__description">
          Нужен для входа на портал через email
        </div>
        <TextField
          :type="showPass ? 'text' : 'password'"
          v-model="password"
          :label="'Введите пароль*'"
          :rules="[
            Rules.isRequired(password, 'Пароль не соответствует требованиям'),
            Rules.onlyLatin(password, 'Пароль не соответствует требованиям'),
            Rules.minLength(password, 'Пароль не соответствует требованиям'),
            Rules.digitsReq(password, 'Пароль не соответствует требованиям'),
            !inputError || inputError,
          ]"
          :successState="
            (Rules.isRequired(password, false) &&
              Rules.onlyLatin(password, false) &&
              Rules.minLength(password, false) &&
              Rules.digitsReq(password, false) &&
              !inputError) ||
            false
          "
          class="mb-8 pass-block"
        >
          <template v-slot:append-icon>
            <div
              class="show-icon"
              @click="showPass = !showPass"
              :class="{ 'show-icon_active': showPass }"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 8C14.5 8 11.5899 12.5 8 12.5C4.41015 12.5 1.5 8 1.5 8C1.5 8 4.41015 3.5 8 3.5C11.5899 3.5 14.5 8 14.5 8Z"
                  stroke="currentColor"
                  stroke-linecap="square"
                />
                <circle
                  cx="8"
                  cy="8"
                  r="2.5"
                  stroke="currentColor"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </template>
          <template v-slot:alternate-error>
            <div class="pass-requirements">
              <div class="req__title">Требования к паролю</div>
              <ul class="req__list mt-2">
                <li
                  class="req__point mb-2"
                  :class="[
                    Rules.onlyLatin(password, false) ? 'success' : 'error',
                  ]"
                >
                  Только латинские буквы
                </li>

                <li
                  class="req__point"
                  :class="[
                    Rules.minLength(password, false) ? 'success' : 'error',
                  ]"
                >
                  Минимум 8 символов
                </li>

                <li
                  class="req__point"
                  :class="[
                    Rules.digitsReq(password, false) ? 'success' : 'error',
                  ]"
                >
                  Минимум одна цифра
                </li>
              </ul>
            </div>
          </template>
        </TextField>
        <TextField
          :type="showPass ? 'text' : 'password'"
          v-model="passwordRepeat"
          :label="'Повторите пароль*'"
          :rules="[
            Rules.isRequired(passwordRepeat, 'Пароли не совпадают'),
            passwordRepeat === password || 'Пароли не совпадают',
          ]"
          :successState="passwordRepeat === password"
          class="pass-block"
        >
          <template v-slot:append-icon>
            <div
              class="show-icon"
              @click="showPass = !showPass"
              :class="{ 'show-icon_active': showPass }"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.5 8C14.5 8 11.5899 12.5 8 12.5C4.41015 12.5 1.5 8 1.5 8C1.5 8 4.41015 3.5 8 3.5C11.5899 3.5 14.5 8 14.5 8Z"
                  stroke="currentColor"
                  stroke-linecap="square"
                />
                <circle
                  cx="8"
                  cy="8"
                  r="2.5"
                  stroke="currentColor"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </template>
        </TextField>

        <div class="buttons-container">
          <div
            class="register-step__button button_empty-pink back button"
            @click="step = 5"
          >
            Назад
          </div>
          <div
            class="register-step__button button_pink big-button button"
            @click="validate(8, Register)"
          >
            Зарегистрироваться
          </div>
        </div>
      </div>
      <PersonalityTest
        :showContinue="true"
        @save="save"
        @continueEv="continueEv"
        v-if="$route.name !== 'TgRegister' && step === 8"
      />
      <div
        class="register-step"
        v-if="
          ($route.name === 'TgRegister' && step === 8) ||
          ($route.name !== 'TgRegister' && step === 9)
        "
      >
        <div v-if="$route.name === 'TgRegister'">
          <div class="register-step__title">Спасибо!</div>
          <div class="register-step__description">
            Регистрация завершена.
            <br />
            <br />
            Подтверждаем ваш статус специалиста здравоохранения. После проверки
            откроем вам полный доступ. Сейчас у вас временный - можете перейти
            на главную страницу и познакомиться с некоторыми функциями сайта, а
            также перейти в наш Telegram - канал
          </div>
        </div>
        <div v-else>
          <div class="register-step__title">Регистрация завершена</div>
          <div class="register-step__description">
            {{ name }}, спасибо, что зарегистрировались на портале AZ Мост!
            <br />
            <br />
            Отправили вашу учётную запись на верификацию, подтверждаем ваш
            статус специалиста здравоохранения. Когда всё проверим — сообщим вам
            по email или позвоним. Откроем полный доступ к порталу.
            <br />
            <br />
            Сейчас у вас есть неполный доступ — можете перейти на главную и
            познакомиться с некоторыми функциями
            {{
              (speciality[0] && tgSpeciality.includes(speciality[0]))
                ? ", а также перейти в наш Telegram-канал, созданный для специалистов здравоохранения."
                : "."
            }}
          </div>
        </div>

        <div
          v-if="$route.name === 'TgRegister'"
          @click="goToTg()"
          class="register-step__button button_pink button px-4"
        >
          Перейти в Telegram
        </div>
        <div v-else class="d-flex">
          <div
            @click="returnToSite()"
            class="register-step__button button_pink button px-4 mr-4"
          >
            На главную
          </div>
          <div
            v-if="speciality[0] && tgSpeciality.includes(speciality[0].toLowerCase()) && false"
            @click="goToTg()"
            class="register-step__button button_empty-pink button px-4"
          >
            Перейти в Telegram
          </div>
        </div>
      </div>
    </form>
    <RegisterFooter />
  </div>
</template>

<script>
import Checkbox from "@/components/form-elements/Checkbox.vue";
import RegisterFooter from "./RegisterFooter.vue";
import TextField from "@/components/form-elements/TextField.vue";
import Select from "@/components/form-elements/Select.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import GetCode from "@/components/form-elements/getCode.vue";
import { bus } from "@/main";
import Preloader from "../Preloader.vue";
import PersonalityTest from "../../components/pageComponents/cabinet/PersonalityTest.vue";
export default {
  components: {
    TextField,
    RegisterFooter,
    Checkbox,
    Select,
    GetCode,
    Preloader,
    PersonalityTest,
  },
  name: "RegisterForm",
  data: () => ({
    backUrl: { name: "MainPage" },
    tgSpeciality: [
      "аллергология детская",
      "гастроэнтерология детская",
      "дерматология детская",
      "неонатология",
      "педиатрия",
      "стоматология детская",
      "терапия подростковая",
      "хирургия детская",
    ],
    stepTriggers: [
      {
        screenNumber: 1,
        stepNumber: 2,
      },
      {
        screenNumber: 3,
        stepNumber: 2,
      },
      {
        screenNumber: 4,
        stepNumber: 3,
      },
      {
        screenNumber: 5,
        stepNumber: 4,
      },
      {
        screenNumber: 8,
        stepNumber: 4,
      },
      {
        screenNumber: 9,
        stepNumber: 4,
      },
    ],
    letterCode: "",
    subscribeEmail: true,
    subscribePhone: true,
    smsCode: "",
    name: "",
    lastName: "",
    secondName: "",
    institution: "",
    address: "",
    department: "",
    showPass: false,
    email: "",
    personalData: false,
    iAmMedic: false,
    speciality: [],
    isNotDepartment: false,
    phoneNumber: "",
    noPhone: false,
    step: 1,
    password: "",
    passwordRepeat: "",
    specialties: [
      {
        label: "Администрация",
        value: "Администрация",
      },
      {
        label: "Акушерское дело",
        value: "Акушерское дело",
      },
      {
        label: "Акушерство и гинекология",
        value: "Акушерство и гинекология",
      },
      {
        label: "Аллергология Детская",
        value: "Аллергология Детская",
      },
      {
        label: "Аллергология",
        value: "Аллергология",
      },
      {
        label: "Ангиохирургия",
        value: "Ангиохирургия",
      },
      {
        label: "Андрология",
        value: "Андрология",
      },
      {
        label: "Анестезиология",
        value: "Анестезиология",
      },
      {
        label: "Бактериология",
        value: "Бактериология",
      },
      {
        label: "Биохимия",
        value: "Биохимия",
      },
      {
        label: "Венерология",
        value: "Венерология",
      },
      {
        label: "Вирусология",
        value: "Вирусология",
      },
      {
        label: "Гастроэнтерология",
        value: "Гастроэнтерология",
      },
      {
        label: "Гастроэнтерология Детская",
        value: "Гастроэнтерология Детская",
      },
      {
        label: "Гематология",
        value: "Гематология",
      },
      {
        label: "Генетика",
        value: "Генетика",
      },
      {
        label: "Гепатология",
        value: "Гепатология",
      },
      {
        label: "Гериатрия",
        value: "Гериатрия",
      },
      {
        label: "Гигиена",
        value: "Гигиена",
      },
      {
        label: "Гинекология",
        value: "Гинекология",
      },
      {
        label: "Гинекология Детская",
        value: "Гинекология Детская",
      },
      {
        label: "Гомеопатия",
        value: "Гомеопатия",
      },
      {
        label: "Дерматология",
        value: "Дерматология",
      },
      {
        label: "Дерматология Детская",
        value: "Дерматология Детская",
      },
      {
        label: "Диетология",
        value: "Диетология",
      },
      {
        label: "Иглорефлексотерапия",
        value: "Иглорефлексотерапия",
      },
      {
        label: "Иммунология",
        value: "Иммунология",
      },
      {
        label: "Инфекционные болезни",
        value: "Инфекционные болезни",
      },
      {
        label: "Кардиология",
        value: "Кардиология",
      },
      {
        label: "Кардиореаниматология",
        value: "Кардиореаниматология",
      },
      {
        label: "Клиническая лабораторная диагностика",
        value: "Клиническая лабораторная диагностика",
      },
      {
        label: "Клиническая фармакология",
        value: "Клиническая фармакология",
      },
      {
        label: "Колопроктология",
        value: "Колопроктология",
      },
      {
        label: "Комбустиология",
        value: "Комбустиология",
      },
      {
        label: "Косметология",
        value: "Косметология",
      },
      {
        label: "Логопедия",
        value: "Логопедия",
      },
      {
        label: "Лучевая терапия",
        value: "Лучевая терапия",
      },
      {
        label: "Маммология",
        value: "Маммология",
      },
      {
        label: "Мануальная терапия",
        value: "Мануальная терапия",
      },
      {
        label: "Микология",
        value: "Микология",
      },
      {
        label: "Микробиология",
        value: "Микробиология",
      },
      {
        label: "Микрохирургия",
        value: "Микрохирургия",
      },
      {
        label: "Морфология",
        value: "Морфология",
      },
      {
        label: "Наркология",
        value: "Наркология",
      },
      {
        label: "Неврология",
        value: "Неврология",
      },
      {
        label: "Неврология Детская",
        value: "Неврология Детская",
      },
      {
        label: "Нейрохирургия",
        value: "Нейрохирургия",
      },
      {
        label: "Неонатология",
        value: "Неонатология",
      },
      {
        label: "Нефрология",
        value: "Нефрология",
      },
      {
        label: "Общая врачебная практика (семейная медицина)",
        value: "Общая врачебная практика (семейная медицина)",
      },
      {
        label: "Онкогинекология",
        value: "Онкогинекология",
      },
      {
        label: "Онкология",
        value: "Онкология",
      },
      {
        label: "Онкология Детская",
        value: "Онкология Детская",
      },
      {
        label: "Онкоурология",
        value: "Онкоурология",
      },
      {
        label: "Онкохирургия",
        value: "Онкохирургия",
      },
      {
        label: "Ортодонтия",
        value: "Ортодонтия",
      },
      {
        label: "Ортопедия",
        value: "Ортопедия",
      },
      {
        label: "Отоларингология Детская",
        value: "Отоларингология Детская",
      },
      {
        label: "Оториноларингология",
        value: "Оториноларингология",
      },
      {
        label: "Офтальмология",
        value: "Офтальмология",
      },
      {
        label: "Паразитология",
        value: "Паразитология",
      },
      {
        label: "Пародонтология",
        value: "Пародонтология",
      },
      {
        label: "Патологическая анатомия",
        value: "Патологическая анатомия",
      },
      {
        label: "Педиатрия",
        value: "Педиатрия",
      },
      {
        label: "Пластическая хирургия",
        value: "Пластическая хирургия",
      },
      {
        label: "Профпатология",
        value: "Профпатология",
      },
      {
        label: "Психиатрия",
        value: "Психиатрия",
      },
      {
        label: "Психиатрия Детская и Подростковая",
        value: "Психиатрия Детская и Подростковая",
      },
      {
        label: "Психология",
        value: "Психология",
      },
      {
        label: "Психоневрология",
        value: "Психоневрология",
      },
      {
        label: "Психотерапия",
        value: "Психотерапия",
      },
      {
        label: "Пульмонология",
        value: "Пульмонология",
      },
      {
        label: "Реабилитация",
        value: "Реабилитация",
      },
      {
        label: "Реаниматология",
        value: "Реаниматология",
      },
      {
        label: "Реаниматология Детская",
        value: "Реаниматология Детская",
      },
      {
        label: "Ревматология",
        value: "Ревматология",
      },
      {
        label: "Ревматология Детская",
        value: "Ревматология Детская",
      },
      {
        label: "Рентгенодиагностика",
        value: "Рентгенодиагностика",
      },
      {
        label: "Сексопатология",
        value: "Сексопатология",
      },
      {
        label: "Сердечно-сосудистая хирургия",
        value: "Сердечно-сосудистая хирургия",
      },
      {
        label: "Сестринское дело",
        value: "Сестринское дело",
      },
      {
        label: "Скорая медицинская помощь",
        value: "Скорая медицинская помощь",
      },
      {
        label: "Спортивная медицина",
        value: "Спортивная медицина",
      },
      {
        label: "Стоматологическое Учреждение",
        value: "Стоматологическое Учреждение",
      },
      {
        label: "Стоматология Детская",
        value: "Стоматология Детская",
      },
      {
        label: "Стоматология ортопедическая",
        value: "Стоматология ортопедическая",
      },
      {
        label: "Стоматология терапевтическая",
        value: "Стоматология терапевтическая",
      },
      {
        label: "Стоматология Хирургическая",
        value: "Стоматология Хирургическая",
      },
      {
        label: "Судебно-медицинская экспертиза",
        value: "Судебно-медицинская экспертиза",
      },
      {
        label: "Судебно-психиатрическая экспертиза",
        value: "Судебно-психиатрическая экспертиза",
      },
      {
        label: "Сурдология",
        value: "Сурдология",
      },
      {
        label: "Терапия",
        value: "Терапия",
      },
      {
        label: "Терапия Подростковая",
        value: "Терапия Подростковая",
      },
      {
        label: "Токсикология",
        value: "Токсикология",
      },
      {
        label: "Торакальная хирургия",
        value: "Торакальная хирургия",
      },
      {
        label: "Травматология",
        value: "Травматология",
      },
      {
        label: "Травматология и ортопедия",
        value: "Травматология и ортопедия",
      },
      {
        label: "Трансплантология",
        value: "Трансплантология",
      },
      {
        label: "Трансфузиология",
        value: "Трансфузиология",
      },
      {
        label: "Ультразвуковая диагностика",
        value: "Ультразвуковая диагностика",
      },
      {
        label: "Урология",
        value: "Урология",
      },
      {
        label: "Фармация",
        value: "Фармация",
      },
      {
        label: "Физиология",
        value: "Физиология",
      },
      {
        label: "Физиотерапия",
        value: "Физиотерапия",
      },
      {
        label: "Фтизиатрия",
        value: "Фтизиатрия",
      },
      {
        label: "Функциональная диагностика",
        value: "Функциональная диагностика",
      },
      {
        label: "Химиотерапия",
        value: "Химиотерапия",
      },
      {
        label: "Хирургия",
        value: "Хирургия",
      },
      {
        label: "Хирургия Детская",
        value: "Хирургия Детская",
      },
      {
        label: "Цитология",
        value: "Цитология",
      },
      {
        label: "Челюстно-лицевая хирургия",
        value: "Челюстно-лицевая хирургия",
      },
      {
        label: "Эмбриология",
        value: "Эмбриология",
      },
      {
        label: "Эндокринология",
        value: "Эндокринология",
      },
      {
        label: "Эндокринология Детская",
        value: "Эндокринология Детская",
      },
      {
        label: "Эндоскопия",
        value: "Эндоскопия",
      },
      {
        label: "Эпидемиология",
        value: "Эпидемиология",
      },
      {
        label: "Эпилептология",
        value: "Эпилептология",
      },
      {
        label: "Прочее",
        value: "Прочее",
      },
    ],
    userData: {
      first_name: "",
      last_name: "",
      middle_name: "",
      speciality: "",
      organization: "",
      organization_address: "",
      department: "",
    },
  }),
  computed: {
    ...mapGetters([
      "Rules",
      "snaplogicData",
      "codeConfirmed",
      "smsConfirmed",
      "emailAlreadyExists",
      "phoneAlreadyExists",
      "inputError",
      "emailSended",
      "user",
      "tgLink",
    ]),
    emailFromQuery() {
      if (this.$route?.query?.email === this.email) {
        return true;
      }
      return null;
    },
    snaplogicDataLength() {
      return Object.keys(this.snaplogicData).length;
    },
    easyUserData() {
      return {
        ...this.userData,
        email: this.email,
        agree_sms: this.subscribePhone,
        agree_email: this.subscribeEmail,
      };
    },
  },
  methods: {
    ...mapActions([
      "checkEmail",
      "checkEmailCode",
      "checkPhone",
      "checkPhoneResend",
      "checkPhoneCode",
      "registration",
      "clearInputError",
      "clearEmailExist",
      "changeUserInfo",
      "easyRegister",
    ]),
    ...mapMutations(["updateCodeConfirmed", "updateSmsConfirmed"]),
    async emailVerify() {
      if (this.email.includes("@astrazeneca.com")) {
        await this.validate(
          null,
          window.open(
            "https://login.microsoftonline.com/af8e89a3-d9ac-422f-ad06-cc4eb4214314/oauth2/v2.0/authorize?client_id=4801abdb-5bbb-415a-b78f-aa34886ffd31&scope=api://4801abdb.astrazeneca.net/user_impersonation&response_type=token",
            "_self"
          )
        );
      } else {
        this.validate(this.emailFromQuery ? 1 : 2, this.checkEmail, {
          email: this.email.toLowerCase(),
          easy: this.emailFromQuery,
        });
      }
    },
    continueEv() {
      this.step++;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "registration personolized content skip", {
          "registration personolized content skip": {
            ...this.$root.ymFields,
          },
        });
      }
    },
    returnToSite() {
      this.$router.push(this.backUrl);
    },
    goToTg() {
      if (this.tgLink) {
        window.open(this.tgLink, "_blank");
      }
      this.$router.push(this.backUrl);
    },
    save(diseases, nosologys, nosologysList) {
      let diseasesArr = diseases.map((el) => el.value);
      let diseasesYm = diseases.map((el) => el.label).join(",");
      let nosologysYm = nosologysList.map((el) => el.title).join(",");
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "registration personolized content save", {
          "registration personolized content save": {
            "selected therapeutic areas": nosologysYm,
            "selected diseases": diseasesYm,
            ...this.$root.ymFields,
          },
        });
      }
      this.changeUserInfo({
        id: this.user.id,
        fields: {
          specialities: nosologys,
          diseases: diseasesArr,
        },
      });
      this.step++;
    },
    changeEmail() {
      this.step--;
      this.updateCodeConfirmed(null);
      this.letterCode = "";
    },
    changePhone() {
      this.step--;
      this.updateSmsConfirmed(null);
      this.smsCode = "";
    },
    async validate(toStep, callback, arg) {
      let res = [];
      bus.$emit("validate", this._uid);
      res = bus.data.result;
      if (!res.includes(false) && res.length) {
        if (callback && typeof callback === "function") {
          let result = await callback(arg);
          if (result) {
            this.step = toStep;
          }
          bus.data.result = [];
        } else {
          bus.data.result = [];
          if (toStep) {
            this.step = toStep;
          }
        }
      } else {
        bus.data.result = [];
      }
    },
    fillUserData() {
      this.userData.first_name = this.name;
      this.userData.last_name = this.lastName;
      this.userData.middle_name = this.secondName;
      this.userData.speciality = this.speciality[0];
      this.userData.organization = this.institution;
      this.userData.organization_address = this.address;
      this.userData.department = this.department;
      this.userData.password = this.password;
      this.userData.default_photo = "/content/cabinet/default-avatar.svg";
      if(this.$root.register) {
        
        this.userData.source = this.$route.path
      
      } else {
        if (localStorage.getItem("source")) {
        this.userData.source = localStorage.getItem("source");
      }
      }
      
    },
    async Register() {
      const vm = this;
      this.fillUserData();
      if (this.$route?.query?.email === this.email) {
        if (await this.easyRegister(this.easyUserData)) {
          return true;
        }
      } else {
        if (await this.registration(this.userData)) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "registration success", {
              "registration success": {
                ...this.$root.ymFields,
              },
            });
          }
          if (this.$root.register) {
            this.$set(this.$root, "register", false);
          } else {
            if (
              localStorage.getItem("backUrl") &&
              this.$router.resolve(JSON.parse(localStorage.getItem("backUrl")))
                .resolved.name !== "404"
            ) {
              this.backUrl = JSON.parse(localStorage.getItem("backUrl"));
            }
            localStorage.removeItem("source");
            localStorage.removeItem("backUrl");
          }
          return true;
        }
      }
    },
  },
  mounted() {
    if (this.$route?.query?.email) {
      this.email = this.$route.query.email;
    }
    if (this.$route?.name === "TgRegister") {
      localStorage.setItem("source", "TgRegister");
    }
  },
  watch: {
    snaplogicDataLength() {
      if (this.snaplogicDataLength) {
        setTimeout(() => {
          this.step = 5;
        }, 100);
      } else {
        setTimeout(() => {
          this.step = 3;
        }, 100);
      }
    },
    step() {
      document.body.scrollIntoView({ behavior: "smooth" });
      this.showPass = false;
      let step = this.stepTriggers.find((el) => el.screenNumber === this.step);
      if (step) {
        let hideLeft = this.step === 8;
        this.$emit("changeStep", step.stepNumber, hideLeft);
      }
    },
    async letterCode() {
      if (this.letterCode.length >= 4) {
        await this.checkEmailCode({
          code: this.letterCode,
          subscribe: this.subscribeEmail,
        });
        this.validate();
      } else {
        this.updateCodeConfirmed(null);
      }
    },
    async smsCode() {
      if (this.smsCode.length >= 4) {
        await this.checkPhoneCode({
          code: this.smsCode,
          subscribe: this.subscribePhone,
        });
        this.validate();
      }
    },
    async emailAlreadyExists() {
      setTimeout(() => {
        if (this.$route.name === "TgRegister") {
          this.$router.push({
            name: "Login",
            params: { tg: true },
            query: this.$route.query,
          });
        }
        this.validate();
      }, 100);
    },
    async phoneAlreadyExists() {
      setTimeout(() => {
        this.validate();
      }, 100);
    },
    async inputError() {
      setTimeout(() => {
        this.validate();
      }, 100);
    },
    async password() {
      if (this.inputError) {
        this.clearInputError();
      }
    },
    async email() {
      if (this.emailAlreadyExists) {
        this.clearEmailExist();
      }
    },
    async emailSended() {
      if (
        this.snaplogicData &&
        Object.keys(this.snaplogicData).length &&
        this.emailSended === true
      ) {
        this.name = this.snaplogicData.first_name;
        this.lastName = this.snaplogicData.last_name;
        this.secondName = this.snaplogicData.middle_name;
        this.institution = this.snaplogicData.organization;
        this.address = this.snaplogicData.organization_address;
        this.phoneNumber = this.snaplogicData.phone.replace("+7", "");
        this.speciality[0] = this.snaplogicData.speciality;
        this.department = this.snaplogicData.role;
      } else if (
        this.snaplogicData &&
        !!!Object.keys(this.snaplogicData).length &&
        this.emailSended === true &&
        this.emailFromQuery
      ) {
        await this.checkEmail({
          email: this.email.toLowerCase(),
          easy: false,
        });
        setTimeout(() => {
          if (!this.emailAlreadyExists) {
            this.step = 2;
          }
        }, 100);
      }
    },
    async codeConfirmed() {
      if (this.snaplogicData && Object.keys(this.snaplogicData).length) {
        this.email = this.snaplogicData.email;
        this.name = this.snaplogicData.first_name;
        this.lastName = this.snaplogicData.last_name;
        this.secondName = this.snaplogicData.middle_name;
        this.institution = this.snaplogicData.organization;
        this.address = this.snaplogicData.organization_address;
        this.phoneNumber = this.snaplogicData.phone.replace("+7", "");
        this.speciality[0] = this.snaplogicData.speciality;
        this.department = this.snaplogicData.role;
      }
    },
    async noPhone() {
      if (!this.noPhone) {
        this.subscribePhone = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.register-form {
  margin: 0 auto;
  width: 592px;

  @media screen and (max-width: 1220px) {
    width: 100%;
    margin: 40px 0 0;
  }
}

.register-step {
  &__title {
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 23px;
    }
  }

  &__description {
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #3c4242;

    &_pink {
      text-decoration-line: underline;
      color: #d0006f;
      cursor: pointer;
    }
  }
  &__button.back {
    width: 92px;
    margin-right: 16px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 16px;
      margin-right: 0;
    }
  }
  &__button {
    margin-top: 32px;
    width: fit-content;
    height: 46px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .big-button {
    width: 229px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .buttons-container {
    display: flex;

    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
  .show-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #3c4242;
    cursor: pointer;

    &_active {
      color: #d0006f;
    }
  }
}
</style>

<style lang="scss">
.req__list {
  list-style: disc;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  width: 350px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  .req__point {
    margin-right: 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #b2b4b4;
    list-style-position: inside;
    &:last-child {
      margin-right: 0;
    }
    &.error {
      color: #cb4050;
    }
  }
}

.req__title {
  margin: 8px 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #3c4242;
}

.pass-block {
  .textfield__input-success {
    right: 30px;
  }
}

.register-step {
  &__refresh-code {
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
</style>
