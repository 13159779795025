<template>
  <div class="auth">
    <div class="content">
      <div class="auth__row">
        <RegisterSteps
          class="full-width"
          :title="
            $route.params.tg
              ? 'Ваш аккаунт уже зарегистрирован'
              : 'Вход на сайт'
          "
          :description="'Используйте свой email или номер телефона для входа на сайт'"
        />
        <form class="auth__form" action="javascript:void(0)">
          <div class="register-step">
            <div class="header-auth">
              <div class="header-auth__items">
                <div
                  class="header-auth__item"
                  @click="tab = 1"
                  :class="{ 'header-auth__item_active': tab === 1 }"
                >
                  Телефон
                </div>
                <div
                  class="header-auth__item"
                  @click="tab = 2"
                  :class="{ 'header-auth__item_active': tab === 2 }"
                >
                  Email
                </div>
              </div>
              <div class="header-auth__tab" v-if="tab === 1">
                <div v-if="!codeSend">
                  <TextField
                    :type="'tel'"
                    :maskString="'+7 (###) ###-##-##'"
                    :placeholder="'+7(---) --- -- --'"
                    v-model="phoneNumber"
                    :rules="[
                      Rules.isRequired(phoneNumber, 'Некорректный номер'),
                      Rules.isCorrectPhone(phoneNumber, 'Некорректный номер'),
                    ]"
                  ></TextField>
                  <div
                    class="header-auth__get-sms button_pink button"
                    @click="validate(getSmsCode)"
                  >
                    Получить код
                  </div>
                </div>
                <div v-else-if="!codeHelp">
                  <div class="header-auth__description">
                    Отправили смс с кодом на номер +7 {{ phoneNumber }}.
                    <span
                      class="header-auth__description_pink"
                      @click="codeSend = false"
                      >Изменить номер?</span
                    >
                  </div>
                  <TextField
                    :type="'number'"
                    v-model="smsCode"
                    :label="'Код из смс*'"
                    :rules="[
                      Rules.isRequired(smsCode, 'Неправильный код'),
                      authSmsConfirmed || 'Неправильный код',
                    ]"
                    :successState="authSmsConfirmed"
                    class="mb-4"
                  >
                    <GetCode
                      :refresh="authPhoneResend"
                      class="ml-4 ml-xs-0 mt-xs-4"
                    />
                  </TextField>

                  <div class="header-auth__code-help" @click="codeHelp = true">
                    Не пришел код
                  </div>
                </div>
                <div v-else>
                  <div class="header-auth__help-caption">Что делать</div>
                  <ol class="header-auth__help-list">
                    <li class="header-auth__help-list-item">
                      Возможно, вы ввели не тот номер или ошиблись в цифре.
                      Проверьте всё ещё раз.
                    </li>
                    <li class="header-auth__help-list-item">
                      Проблема может быть со стороны вашего оператора сотовой
                      связи. Обратитесь в службу поддержки.
                    </li>
                  </ol>
                  <div
                    class="header-auth__back button button_empty-pink"
                    @click="codeHelp = false"
                  >
                    Назад
                  </div>
                </div>
              </div>
              <div class="header-auth__tab" v-if="tab === 2">
                <TextField
                  :type="'email'"
                  v-model="email"
                  :placeholder="'E-mail'"
                  :rules="[
                    Rules.isRequired(email, 'Нужно заполнить Email адрес'),
                    Rules.isCorrectEmail(email, 'Некорректный email'),
                  ]"
                  class="mb-3"
                />
                <TextField
                  :type="showPass ? 'text' : 'password'"
                  v-model="password"
                  :placeholder="'Пароль'"
                  :rules="[Rules.isRequired(password, 'Введите пароль')]"
                  class="pass-block"
                />
                <div class="header-auth__buttons">
                  <div
                    class="header-auth__login button_pink button"
                    @click="validate(auth, authData)"
                  >
                    Войти
                  </div>
                  <router-link
                    :to="{ name: 'RestorePassword' }"
                    class="header-auth__forgot-pass"
                    >Забыли пароль?</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <RegisterFooter>
            <div class="register-block">
              <div class="register-block__title">
                Если у вас нет учетной записи:
              </div>
              <div
                class="register-block__button button button_empty-pink"
                @click="registerState"
              >
                Зарегистрироваться
              </div>
            </div>
            <div class="header-auth__sso mt-8 pt-8 mb-8">
              <a
                href="https://login.microsoftonline.com/af8e89a3-d9ac-422f-ad06-cc4eb4214314/oauth2/v2.0/authorize?client_id=4801abdb-5bbb-415a-b78f-aa34886ffd31&scope=api://4801abdb.astrazeneca.net/user_impersonation&response_type=token"
                class="button_gray button"
              >
                Вход сотрудникам AZ
              </a>
            </div>
          </RegisterFooter>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TextField from "@/components/form-elements/TextField.vue";
import GetCode from "@/components/form-elements/getCode.vue";
import RegisterSteps from "../../components/auth/RegisterSteps.vue";
import RegisterFooter from "../../components/auth/RegisterFooter.vue";

import { bus } from "@/main";
export default {
  metaInfo: {
    title: "Авторизация",
  },
  components: { TextField, GetCode, RegisterSteps, RegisterFooter },
  name: "Login",
  data: () => ({
    tab: 1,
    phoneNumber: "",
    codeSend: false,
    smsCode: "",
    codeHelp: false,
    email: "",
    password: "",
    showPass: "",
  }),
  computed: {
    ...mapGetters(["Rules", "authSmsConfirmed", "inputError"]),
    authData() {
      return {
        username: this.email.toLowerCase(),
        password: this.password,
      };
    },
  },
  methods: {
    ...mapActions([
      "auth",
      "authPhone",
      "authPhoneResend",
      "authPhoneCode",
      "clearInputError",
    ]),
    registerState() {
      this.$set(this.$root, "register", true);
      this.$set(this.$root, "login", false);
    },
    async getSmsCode() {
      if (await this.authPhone(this.phoneNumber)) {
        this.codeSend = true;
      }
    },
    async validate(callback, arg) {
      const vm = this;
      let res = [];
      bus.$emit("validate", this._uid);
      res = bus.data.result;
      if (!res.includes(false) && res.length) {
        if (callback && typeof callback === "function") {
          let result = await callback(arg);
          if (result) {
            bus.data.result = [];
            if (typeof ym !== "undefined") {
              ym(91468266, "reachGoal", "auth success", {
                "auth success": {
                  ...this.$root.ymFields,
                },
              });
            }
            if (this.$root.login) {
              this.$set(this.$root, "login", false);
            } else {
              if (
                localStorage.getItem("backUrl") &&
                this.$router.resolve(
                  JSON.parse(localStorage.getItem("backUrl"))
                ).resolved.name !== "404"
              ) {
                if (
                  this.$route &&
                  this.$route.params &&
                  this.$route.params.tg
                ) {
                  this.$router.push({
                    name: "ToTgChanel",
                    params: { tg: true },
                  });
                } else {
                  this.$router.push(
                    JSON.parse(localStorage.getItem("backUrl"))
                  );
                }
                localStorage.removeItem("backUrl");
              } else {
                if (
                  this.$route &&
                  this.$route.params &&
                  this.$route.params.tg
                ) {
                  this.$router.push({
                    name: "ToTgChanel",
                    params: { tg: true },
                  });
                } else {
                  if (this.$route.params && this.$route.params.toPage) {
                    this.$router.push(this.$route.params.toPage);
                  } else {
                    this.$router.push({ name: "MainPage" });
                  }
                }
              }
            }
          }
        }
      }
      bus.data.result = [];
    },
  },
  mounted() {
    if (this.$route && this.$route.params && this.$route.params.tg) {
      this.tab = 2;
    }
    if (this.$route?.query?.email) {
      this.email = this.$route.query.email;
    }
  },
  watch: {
    async smsCode() {
      const vm = this;
      if (this.smsCode.length >= 4) {
        let res = await this.authPhoneCode(this.smsCode);
        this.validate();
        if (res) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "auth success", {
              "auth success": {
                ...this.$root.ymFields,
              },
            });
          }
          if (this.$root.login) {
            this.$set(this.$root, "login", false);
          } else {
            if (
              localStorage.getItem("backUrl") &&
              this.$router.resolve(JSON.parse(localStorage.getItem("backUrl")))
                .resolved.name !== "404"
            ) {
              if (this.$route && this.$route.params && this.$route.params.tg) {
                this.$router.push({ name: "ToTgChanel", params: { tg: true } });
              } else {
                this.$router.push(JSON.parse(localStorage.getItem("backUrl")));
              }
              localStorage.removeItem("backUrl");
            } else {
              if (this.$route && this.$route.params && this.$route.params.tg) {
                this.$router.push({ name: "ToTgChanel", params: { tg: true } });
              } else {
                if (this.$route.params && this.$route.params.toPage) {
                  this.$router.push(this.$route.params.toPage);
                } else {
                  this.$router.push({ name: "MainPage" });
                }
              }
            }
          }
        }
      }
    },
    async inputError() {
      setTimeout(() => {
        this.validate();
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.full-width {
  @media screen and (max-width: 1220px) {
    width: 100%;
  }
}
.auth {
  height: 100%;
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 1220px) {
    padding: 40px 0;
    height: auto;
  }
  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__form {
    width: 595px;
    margin: 0 auto;
    @media screen and (max-width: 1220px) {
      width: 100%;
    }
  }
}

.register-block {
  &__title {
    margin-bottom: 24px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #3c4242;
  }

  &__button {
    width: 217px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.auth-step {
  &__title {
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 23px;
    }
  }

  &__description {
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #3c4242;

    &_pink {
      text-decoration-line: underline;
      color: #d0006f;
      cursor: pointer;
    }
  }
}

.header-auth {
  width: 384px;
  @media screen and (max-width: 1220px) {
    margin-top: 40px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 32px;
  }
  &__tab {
    width: 100%;
  }

  &__get-sms {
    width: 170px;
    height: 40px;
    margin-top: 16px;
  }

  &__login {
    width: 100%;
    height: 40px;
  }

  &__title {
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: #424753;
  }

  &__items {
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #d2d2d2;
  }

  &__item {
    width: 100%;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #b2b4b4;
    padding-bottom: 8px;
    cursor: pointer;

    &_active {
      color: #3c4242;
      border-bottom: 2px solid #d0006f;
    }
  }

  &__description {
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #3c4242;

    &_pink {
      text-decoration-line: underline;
      color: #d0006f;
      cursor: pointer;
    }
  }

  &__code-help {
    margin-top: 6px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #830051;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

  &__help-caption {
    margin-bottom: 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #424753;
  }

  &__help-list {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #666666;
    padding-left: 15px;
    list-style-type: number;
  }

  &__help-list-item {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__back {
    margin-top: 16px;
    padding: 4px 16px;
    display: inline-flex;
  }

  &__forgot-pass {
    margin-left: 16px;
    flex-shrink: 0;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-decoration-line: underline;
    color: #830051;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: #d0006f;
    }

    @media screen and (max-width: 767px) {
      margin-top: 16px;
    }
  }

  &__buttons {
    margin-top: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin-top: 24px;
    }
  }
}
</style>

<style lang="scss">
.auth .get-code__refresh-code {
  width: 100%;
}
.header .header-auth {
  &__sso {
    border-top: 1px solid #ebefee;

    & .button {
      width: 217px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}
</style>