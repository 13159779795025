import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

function load(component) {
  return () => import(`@/views/${component}.vue`);
}

Vue.use(VueRouter);
let routes = [];
if (process.env.VUE_APP_CLOSED && !document.cookie.includes("access")) {
  routes = [
    {
      path: "*",
      name: "Plug",
      component: load("Plug"),
      meta: {
        needAuth: false,
        layout: "empty",
      },
    },
  ];
} else {
  routes = [
    {
      path: "/",
      name: "MainPage",
      component: load("MainPage"),
      meta: {
        layout: "main",
      },
    },
    {
      path: "/therapeutic-areas/:slug",
      name: "NosologyDetail",
      component: load("NosologyDetail"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/therapeutic-areas/:slug/:disease",
      name: "Disease",
      component: load("Disease"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/register",
      name: "Register",
      component: load("auth/Register"),
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/tg-register",
      name: "TgRegister",
      component: load("auth/Register"),
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/login",
      name: "Login",
      component: load("auth/Login"),
      meta: {
        layout: "auth",
      },
    },
    // {
    //   path: '/user-update',
    //   name: 'UserUpdate',
    //   component: load('auth/UserUpdate'),
    //   meta: {
    //     layout: 'auth'
    //   }
    // },
    {
      path: "/library",
      name: "Library",
      component: load("library/Library"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/library/videomaterials/",
      name: "VideoMaterials",
      component: load("library/VideoMaterials"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/library/podcasts/",
      name: "Podcasts",
      component: load("library/Podcasts"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/library/textmaterials",
      name: "TextMaterials",
      component: load("library/TextMaterials"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/library/textmaterials/:slug",
      name: "TextDetail",
      component: load("library/TextDetail"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/library/videomaterials/:slug",
      name: "VideoDetail",
      component: load("library/VideoDetail"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/library/podcasts/:slug",
      name: "PodcastDetail",
      component: load("library/PodcastDetail"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/cabinet",
      name: "Cabinet",
      component: load("Cabinet"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/materials",
      name: "Materials",
      component: load("cabinet/Materials"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/materials/settings",
      name: "MaterialsSettings",
      component: load("cabinet/MaterialsSettings"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/materials/:type(text|podcasts|video|diseases|medications|events)",
      name: "MaterialsDetail",
      component: load("cabinet/MaterialsDetail"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/favorites",
      name: "Favorites",
      component: load("cabinet/Favorites"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/history",
      name: "History",
      component: load("cabinet/History"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/watch-later",
      name: "WatchLater",
      component: load("cabinet/WatchLater"),
      meta: {
        layout: "cabinet",
        auth: true,
      },
    },
    {
      path: "/restore-password",
      name: "RestorePassword",
      component: load("auth/RestorePassword"),
      meta: {
        layout: "auth",
      },
    },
    {
      path: "/therapeutic-areas",
      name: "Nosology",
      component: load("Nosology"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/medications",
      name: "Medications",
      component: load("Medications"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/medication/:medication",
      name: "DrugsTemplates",
      component: load("DrugsTemplates"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/events",
      name: "Events",
      component: load("events/Events"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/event/:id",
      name: "EventDetail",
      component: load("events/EventDetail"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/contacts",
      name: "ContactUs",
      component: load("ContactUs"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/report-adverse-event",
      name: "ReportAdverseEvent",
      component: load("ReportAdverseEvent"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/complaint-to-the-drug",
      name: "ComplaintDrug",
      component: load("ComplaintDrug"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/report-a-drug-claim",
      name: "ReportDrugClaim",
      component: load("ReportDrugClaim"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/medical-info",
      name: "MedicalInfo",
      component: load("MedicalInfo"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/betalok",
      name: "Betalok",
      component: load("Betalok"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/all-rights-reserved",
      name: "AllRightsReserved",
      component: load("AllRightsReserved"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/oauth",
      name: "OAuth",
      component: load("auth/OAuth"),
      meta: {
        layout: "empty",
      },
    },
    {
      path: "/map",
      name: "Map",
      component: load("Map"),
      meta: {
        layout: "empty",
        auth: true,
      },
    },
    // {
    //   path: "/email-safnelo",
    //   name: "EmailSafnelo",
    //   component: load("EmailSafnelo"),
    //   meta: {
    //     layout: "main",
    //     auth: false,
    //   },
    // },
    {
      path: "/skv-academy",
      name: "SkvAcademy",
      component: load("anotherPages/SkvAcademy"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/faq-lald",
      name: "Lald",
      component: load("anotherPages/Lald"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/faq-hpp",
      name: "Gff",
      component: load("anotherPages/Gff"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/bremya_skv",
      name: "Skv",
      component: load("anotherPages/Skv"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/galaxy",
      name: "Galaxy",
      component: load("anotherPages/Galaxy"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/galaxy/:frame",
      name: "GalaxyFrame",
      component: load("anotherPages/GalaxyFrame"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/med-info",
      name: "MedInfo",
      component: load("MedInfo"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/vremya-hobl",
      name: "TimeHobl",
      component: load("anotherPages/TimeHobl"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/vremya-hobl/:frame",
      name: "TimeHoblFrame",
      component: load("anotherPages/TimeHoblFrame"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    // {
    //   path: '/narodnye-antiagregantnye-skazki',
    //   name: 'NarodnyeSkazki',
    //   component: load('anotherPages/NarodnyeSkazki'),
    //   meta: {
    //     layout: 'main',
    //     auth: true
    //   }
    // },
    {
      path: "/stream-test",
      name: "Stream",
      component: load("anotherPages/Stream"),
      meta: {
        layout: "main",
        auth: false,
      },
    },
    {
      path: "/safnelo-p1",
      name: "safneloPartOne",
      component: load("anotherPages/Safnelo-1"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/safnelo-p2",
      name: "safneloPartTwo",
      component: load("anotherPages/Safnelo-2"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "/tg",
      name: "ToTgChanel",
      component: load("ToTgChanel"),
      meta: {
        layout: "empty",
        auth: false,
      },
    },
    {
      path: "/forsiga",
      name: "Forsiga",
      component: load("anotherPages/Forsiga"),
      meta: {
        layout: "main",
        auth: true,
      },
    },
    {
      path: "*",
      name: "404",
      component: load("404"),
      meta: {
        layout: "main",
      },
    },
  ];
}
const vm = this;
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (
        to.hash.replace("#", "") &&
        document.getElementById(to.hash.replace("#", ""))
      ) {
        return {
          selector: to.hash,
        };
      } else {
        if (to.path !== from.path) {
          return { x: 0, y: 0 };
        }
      }
    }
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  const _to = to.path.replace(/\/$/, "");
  //const _from = from.fullPath.replace(/\/$/, "");
  //const _current = window.location.pathname.replace(/\/$/, "");
  const flag = _to !== "" && to.path && to.path.match(/\/$/);
  if (flag) {
    let _to2 = "";
    for (let i = 1; i < to.path.split(/\//).length - 1; i++) {
      _to2 += "/" + to.path.split(/\//)[i];
    }
    next({ ...to, path: _to2 });
  } else next();
  const isAuth = localStorage.getItem("token");
  if (isAuth && isAuth !== "undefined") {
    if (
      to.name === "Login" ||
      to.name === "Register" ||
      to.name === "TgRegister"
    ) {
      if (to.name === "TgRegister") {
        next({
          name: "ToTgChanel",
          params: { tg: true },
        });
      } else {
        next(false)
        // next({
        //   name: "MainPage",
        // });
      }
    }
  }
  // if(to.name === "Login") {
  //   next({...from, params: {login: true, register: false}});
  // }
  // if(to.name === "Register") {
  //   next({...from, params: {login: false, register: true}});
  // }
  if (to.matched.some((record) => record.meta.auth)) {
    if (Object.keys(to.query) && to.query.access) {
      switch (to.name) {
        case "NosologyDetail":
          store.dispatch("fetchNosologyDetail", {
            slug: to.params.slug,
            access: to.query.access,
          });
          break;
        case "Disease":
          store.dispatch("fetchDisease", {
            disease: to.params.disease,
            access: to.query.access,
          });
          break;
        case "EventDetail":
          store.dispatch("fetchEventDetail", {
            id: to.params.id,
            access: to.query.access,
          });
          break;
        case "DrugsTemplates":
          store.dispatch("fetchMedicationDetail", {
            slug: to.params.medication,
            access: to.query.access,
          });
          break;
        case "TextDetail":
          store.dispatch("fetchTextDetail", {
            slug: to.params.slug,
            access: to.query.access,
          });
          break;
        case "VideoDetail":
          store.dispatch("fetchVideoDetail", {
            slug: to.params.slug,
            access: to.query.access,
          });
          break;
        case "PodcastDetail":
          store.dispatch("fetchPodcastDetail", {
            slug: to.params.slug,
            access: to.query.access,
          });
          break;
        case "Map":
          if (to.query.access === "88ed053ef4280c1153ad0add4ee4ae2f") {
            next();
          } else {
             next({...to, params: {...to.params, login: true}});
          }
          break;
        case "Events":
          if (to.query.access === "ZXZlbnRzX3BhZ2VfYXpfbW9zdF9kZWVwbGluaw") {
            next();
          } else {
             next({...to, params: {...to.params, login: true}});
          }
          break;
        case "Betalok":
          if (to.query.access === "72194fe7d6eb1600b9518f0ceab1fde8") {
            next();
          } else {
             next({...to, params: {...to.params, login: true}});
            
          }
          break;
        case "Galaxy":
          if (to.query.access === "e03239b27e34a5f7f3bde739459dd537") {
            next();
          } else {
             next({...to, params: {...to.params, login: true}});
            
          }
          break;
        case "SkvAcademy":
          if (to.query.access === "ce10238dbf11023c43ddf27a9e3b741e") {
            next();
          } else {
            next({ name: "Login", params: { toPage: to } });
          }
          break;
        case "TimeHobl":
          if (to.query.access === "3a7dbae2ea7e84704761e4aba89351a6") {
            next();
          } else {
             next({...to, params: {...to.params, login: true}});
             
          }
          break;
        case "NarodnyeSkazki":
          if (to.query.access === "72d9de5d2c64b936e4f0eddea673b238") {
            next();
          } else {
            next({ name: "Login", params: { toPage: to } });
          }
          break;
        case "safneloPartOne":
          if (to.query.access === "8a8fb39220c0feb3d073ef048d7865e1") {
            next();
          } else {
            next({ name: "Login", params: { toPage: to } });
          }
          break;
        case "safneloPartTwo":
          if (to.query.access === "9220c0feb8a8fb33d073ef048d7865e1") {
            next();
          } else {
            next({ name: "Login", params: { toPage: to } });
          }
          break;
          case "Forsiga":
          if (to.query.access === "0033db14bd55bdb6430356c78161a48a") {
            next();
          } else {
            next({ name: "Login", params: { toPage: to } });
          }
          break;
        default:
           next({...to, params: {...to.params, login: true}});
          
      }
    } else if (!isAuth && (!to.params.register && !to.params.login)) {
      //  next({...to, params: {login: true}});
      next({...to, params: {...to.params, login: true}});
      
    } else {
      next();
    }
  }

  next();
});

export default router;
