export default ({
  state: {
    codeConfirm: null,
    smsConfirm: null,
    emailAlreadyExists: false,
    phoneAlreadyExists: false,
    userCreated: null,
    emailSended: null,
    snaplogicData: {},
    tgLink: ""
  },
  getters: {
    codeConfirmed: s => s.codeConfirm,
    smsConfirmed: s => s.smsConfirm,
    emailAlreadyExists: s => s.emailAlreadyExists,
    phoneAlreadyExists: s => s.phoneAlreadyExists,
    emailSended: s => s.emailSended,
    userCreated: s => s.userCreated,
    snaplogicData: s => s.snaplogicData,
    tgLink: s => s.tgLink
  },
  mutations: {
    updateCodeConfirmed(state, status) {
      state.codeConfirm = status
    },
    updateSmsConfirmed(state, status) {
      state.smsConfirm = status
    },
    updateEmailAlreadyExists(state, status) {
      state.emailAlreadyExists = status
    },
    updatePhoneAlreadyExists(state, status) {
      state.phoneAlreadyExists = status
    },
    updateEmailSended(state, status) {
      state.emailSended = status
    },
    updateUserCreated(state, status) {
      state.userCreated = status
    },
    updateSnaplogicData(state, data) {
      state.snaplogicData = data
    },
    updateTgLink(state, link) {
      state.tgLink = link
    },
  },
  actions: {
    async registration(ctx, userData) {
      const fd = new FormData();
      for (let data in userData) {
        fd.append(data, userData[data])
      }

      if (ctx.getters.userCreated) {
        let access_token = localStorage.getItem('token');
        return await ctx.dispatch('setPassword', { id: ctx.getters.userCreated, pass: userData.password, access_token: access_token })
      } else {
        return await this.$axios({
          method: 'POST',
          url: '/api/auth/registration/',
          data: fd,
        })
          .then(async (response) => {
            const result = response.data;
            localStorage.setItem('token', result.access_token);
            localStorage.setItem('refresh', result.refresh);
            let passStatus = await ctx.dispatch('setPassword', { id: result.id, pass: userData.password, access_token: result.access_token })
            console.log(result)
            if (result.invite_link) {
              ctx.commit('updateTgLink', result.invite_link)
            }
            ctx.commit('updateUserCreated', result.id)
            ctx.dispatch('getSession')
            return passStatus

          })
          .catch((error) => {
            ctx.dispatch('fetchAlerts', { alerts: 'Произошла ошибка', type: 'error' })
            console.error(error)
            return false
          });
      }

    },
    async setPassword(ctx, uData) {
      ctx.commit('updateInputError', false)
      const fd = new FormData();
      fd.append('new_password', uData.pass)

      return await this.$axios({
        method: 'POST',
        url: `/api/auth/registration/${uData.id}/password/`,
        headers: {
          Authorization: `Bearer ${uData.access_token}`
        },
        data: fd,
      })
        .then((response) => {
          this.$axios.defaults.headers.common.Authorization = `Bearer ${uData.access_token}`;
          return true
        })
        .catch((error) => {
          let response = JSON.parse(error.request.response)
          if (response && response.non_field_errors) {
            ctx.commit('updateInputError', response.non_field_errors[0])
          } else {
            ctx.dispatch('fetchAlerts', { alerts: 'Произошла ошибка', type: 'error' })
          }
          console.error(error)
          return false
        });
    },
    async checkEmail(ctx, { email, easy }) {
      const fd = new FormData();
      fd.append('email', email);
      if (easy) {
        ctx.commit('updateEmailSended', 'wait')
        await ctx.dispatch('mdmIdRegister', { email: email })
        ctx.commit('updateEmailSended', true)
        return true

      }
      return this.$axios({
        method: 'POST',
        url: '/api/auth/check-email/send/',
        data: fd,
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }
          ctx.commit('updateEmailSended', true)
          return true
        })
        .catch((error) => {
          if (error.response.data  && error.response.data.errors && error.response.data.errors.email && error.response.data.errors.email === 'User already exists') {
            ctx.commit('updateEmailAlreadyExists', true)
          } else {
            ctx.commit('updateEmailAlreadyExists', false)
            ctx.dispatch('fetchAlerts', { alerts: 'Произошла ошибка', type: 'error' })
          }
          return false
        });
    },
    checkEmailCode(ctx, data) {
      ctx.commit('updateCodeConfirmed', null)
      const fd = new FormData();
      fd.append('pin', data.code);
      if (data.subscribe) {
        fd.append('allow', data.subscribe);
      }


      return this.$axios({
        method: 'POST',
        url: '/api/auth/check-email/pin/',
        data: fd,
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            ctx.commit('updateCodeConfirmed', false)
            return false
          }
          if (response.data) {
            ctx.commit('updateSnaplogicData', response.data)
          }
          ctx.commit('updateCodeConfirmed', true)

          return true
        })
        .catch((error) => {
          console.error(error);
          ctx.commit('updateCodeConfirmed', false)
          return false
        });
    },
    checkPhone(ctx, phone) {
      const fd = new FormData();
      fd.append('phone', `7${phone}`);

      return this.$axios({
        method: 'POST',
        url: '/api/auth/check-phone/send/',
        data: fd,
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }
          return true
        })
        .catch((error) => {
          if (error.response.data && error.response.data.errors.phone && error.response.data.errors.phone === 'User already exists') {
            ctx.commit('updatePhoneAlreadyExists', true)
          } else {
            ctx.commit('updatePhoneAlreadyExists', false)
            ctx.dispatch('fetchAlerts', { alerts: 'Слишком много запросов на отправку СМС-кода, попробуйте позже', type: 'error' })
          }
          return false
        });
    },
    checkPhoneResend(ctx) {
      return this.$axios({
        method: 'POST',
        url: '/api/auth/check-phone/resend/',
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }
          return true
        })
        .catch((error) => {
          ctx.dispatch('fetchAlerts', { alerts: 'Слишком много запросов на отправку СМС-кода, попробуйте позже', type: 'error' })
          console.error(error);
          return false
        });
    },
    checkPhoneCode(ctx, data) {
      const fd = new FormData();
      fd.append('pin', data.code);
      if (data.subscribe) {
        fd.append('allow', data.subscribe);
      }

      return this.$axios({
        method: 'POST',
        url: '/api/auth/check-phone/check/',
        data: fd,
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            ctx.commit('updateSmsConfirmed', false)
            return false
          }
          ctx.commit('updateSmsConfirmed', true)
          return true
        })
        .catch((error) => {
          console.error(error);
          ctx.commit('updateSmsConfirmed', false)
          return false
        });
    },
    mdmIdRegister(ctx, regData) {
      const fd = new FormData();
      for (let data in regData) {
        fd.append(data, regData[data])
      }

      return this.$axios({
        method: 'POST',
        url: '/api/auth/registration/mdmid/',
        data: fd,
        headers: {
          Authorization: null
        },
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {

            return false
          }
          if (response.data) {
            ctx.commit('updateSnaplogicData', response.data)
          }
          return response.data
        })
        .catch((error) => {
          if (error.response.data && error.response.data.errors && error.response.data.errors.email && error.response.data.errors.email === 'User already exists') {
            ctx.commit('updateEmailAlreadyExists', true)
          } else {
            ctx.commit('updateEmailAlreadyExists', false)
            // ctx.dispatch('fetchAlerts', { alerts: 'Произошла ошибка', type: 'error' })
          }

          return false
        });
    },
    mdmIdDelete(ctx, id) {
      return this.$axios({
        method: 'POST',
        url: `/api/auth/registration/${id}/mdmid-delete/`,
      })
        .then((response) => {
          const status = response.data.status;
          if (status >= 400) {
            return false
          }
          ctx.commit('updateUser', {})
          return response.data
        })
        .catch((error) => {
          ctx.dispatch('fetchAlerts', { alerts: 'Произошла ошибка', type: 'error' })
          console.error(error);

          return false
        });
    },
    clearEmailExist(ctx) {
      ctx.commit('updateEmailAlreadyExists', false)
    },
    easyRegister(ctx, regData) {
      const fd = new FormData();
      for (let data in regData) {
        fd.append(data, regData[data])
      }

      return this.$axios({
        method: 'POST',
        url: '/api/auth/registration/easy/',
        data: fd,
        headers: {
          Authorization: null
        },
      })
        .then(async (response) => {
          const status = response.data.status;
          if (status >= 400) {

            return false
          }
          const result = response.data;
          localStorage.setItem('token', result.access_token);
          localStorage.setItem('refresh', result.refresh);
          let passStatus = await ctx.dispatch('setPassword', { id: result.id, pass: regData.password, access_token: result.access_token })
          console.log(result)
          if (result.invite_link) {
            ctx.commit('updateTgLink', result.invite_link)
          }
          ctx.commit('updateUserCreated', result.id)
          ctx.dispatch('getSession')

          return passStatus
        })
        .catch((error) => {
          if (error.response.data && error.response.data.errors && error.response.data.errors.email && error.response.data.errors.email === 'User already exists') {
            ctx.dispatch('fetchAlerts', { alerts: 'Пользователь с таким email уже существует', type: 'error' })
          } else {
            ctx.dispatch('fetchAlerts', { alerts: 'Произошла ошибка', type: 'error' })
          }
          console.error(error);

          return false
        });
    },
  },
})