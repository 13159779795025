export default {
  state: {
    results: [],
  },
  getters: {
    searchResults: s => s.results,
  },
  mutations: {
    updateSearchResults(s, { results, q }) {
      let reg = new RegExp(q, 'gi');
      results.forEach(function(el){
        el.origTitle = el.title || el.name
        el.title = el.title?.replaceAll(reg, (str) => `<strong>${str}</strong>`) || el.name?.replaceAll(reg, (str) => `<strong>${str}</strong>`)
      })
      s.results = results
    },
  },
  actions: {
    async fetchSearch(ctx, q) {
        return this.$axios({
          method: 'GET',
          url: `/api/content/search?query=${q}`,
        })
          .then((response) => {
            const status = response.data.status;
            if (status >= 400) {
              return false
            }
            ctx.commit('updateSearchResults', { results: response.data, q: q })
            return true
          })
          .catch((error) => {
            ctx.dispatch('fetchAlerts', {alerts: 'Произошла ошибка', type: 'error'})
            console.error(error);
            return false
          });
    },
    clearSearch(ctx) {
      ctx.commit('updateSearchResults', { results: [], q: '' })
    }
  },
}
