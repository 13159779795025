<template>
  <div
    class="textfield"
    :class="[
      {
        textfield_error: isError,
        textfield_success: !isError && inputVal && successState,
        textfield_disabled: disabled,
      },
    ]"
  >
    <div class="textfield__label" v-if="label">{{ label }}</div>
    <div class="textfield__outer">
      <div class="textfield__input-container">
        <vue-dadata
          :onChange="suggestion"
          class="textfield__input"
          v-if="type === 'address'"
          :query="inputVal"
          :token="token"
          @input.native="suggestion({ value: '' })"
        />
        <the-mask
          :disabled="disabled"
          v-if="maskString"
          :mask="maskString"
          class="textfield__input"
          v-model="inputVal"
          :type="type"
          :readonly="readonly"
          :masked="false"
          :placeholder="placeholder"
        ></the-mask>
        <input
          v-else-if="type !== 'address'"
          autocomplete="off"
          class="textfield__input"
          :type="type"
          :readonly="readonly"
          v-model="inputVal"
          :placeholder="placeholder"
          @change="validate()"
          :disabled="disabled"
        />
        <span
          class="textfield__input-success"
          v-if="!isError && inputVal && successState"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 4L6.5 11L3 7"
              stroke="#C4D600"
              stroke-linecap="square"
            />
          </svg>
        </span>
        <slot name="append-icon"></slot>
      </div>
      <slot></slot>
    </div>
    <div
      class="textfield__error"
      v-if="isError && errorText && !returnSlots['alternate-error']"
      v-html="errorText"
    ></div>
    <div class="textfield__error"><slot name="alternate-error"></slot></div>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import validate from "@/mixins/validate";
import VueDadata from "vue-dadata";
import { bus } from "@/main";
export default {
  name: "TextField",
  mixins: [validate],
  components: { TheMask, "vue-dadata": VueDadata },
  props: {
    value: String,
    type: String,
    placeholder: String,
    label: String,
    successState: Boolean,
    rules: Array,
    maskString: String,
    disabled: Boolean,
    readonly: Boolean,
  },
  data: () => ({
    errorText: "",
    isError: false,
    token: process.env.VUE_APP_DADATA_API_KEY,
    deleteSpaces: false,
    inputTimer: null,
  }),
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    returnSlots() {
      return this.$slots
    }
  },
  methods: {
    suggestion(el) {
      this.inputVal = el.value;
    },
  },
  created() {
    bus.$on("validate", this.validate);
    if (this.type === "password") {
      this.deleteSpaces = true;
    }
  },
  destroyed() {
    bus.$off("validate", this.validate);
  },
  watch: {
    inputVal() {
      if (this.deleteSpaces) {
        this.inputVal = this.inputVal.replace(" ", "");
      }
      if (this.isError) {
        bus.data.result = [];
        this.validate(this._uid);
      }
    },
    disabled() {
      if (this.disabled) {
        this.inputVal = "";
        this.isError = false;
        this.errorText = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.textfield {
  width: 100%;
  &__label {
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      margin-bottom: 12px;
    }
  }

  &__input {
    padding: 0 16px;
    width: 100%;
    height: 40px;
    max-width: 384px;
    background-color: #f8f8f8;
    border: 1px solid #d2d2d2;
    border-radius: 99px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #3c4242;
    display: block;
    &::placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #b2b4b4;
      opacity: 1; /* Firefox */
    }

    &:focus {
      border-color: #d0006f;
    }

    &-container {
      position: relative;
      max-width: 384px;
      width: 100%;
    }

    &-success {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }
  &__input:disabled {
    background-color: #ebefee;
  }

  &__error {
    margin-top: 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #cb4050;
  }

  &__outer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &_error {
    & .textfield__input {
      border-color: #cb4050;
      background-color: #faebed;
      color: #cb4050;
    }

    & .textfield__error * {
      color: #cb4050 !important;
    }
  }

  &_success {
    & .textfield__input {
      border-color: #c4d600;
      background-color: #fafce9;
    }
  }
  &_disabled &__label {
    color: #b2b4b4;
  }
}
</style>
<style>
.textfield__error a {
  text-decoration: underline;
}

.textfield .vue-dadata__input {
  height: 100%;
  background-color: transparent;
  border: 0 !important;
}

.textfield .vue-dadata__input:focus {
  box-shadow: none;
  border-color: transparent;
}

.textfield .vue-dadata__container,
.textfield .vue-dadata__search {
  height: 100%;
}

.textfield .vue-dadata__suggestions-item:hover {
  color: #fff;
  background-color: #830051 !important;
}

.textfield mark {
  background-color: transparent;
}
</style>