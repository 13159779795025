var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"auth__row"},[_c('RegisterSteps',{staticClass:"full-width",attrs:{"title":_vm.$route.params.tg
            ? 'Ваш аккаунт уже зарегистрирован'
            : 'Вход на сайт',"description":'Используйте свой email или номер телефона для входа на сайт'}}),_c('form',{staticClass:"auth__form",attrs:{"action":"javascript:void(0)"}},[_c('div',{staticClass:"register-step"},[_c('div',{staticClass:"header-auth"},[_c('div',{staticClass:"header-auth__items"},[_c('div',{staticClass:"header-auth__item",class:{ 'header-auth__item_active': _vm.tab === 1 },on:{"click":function($event){_vm.tab = 1}}},[_vm._v(" Телефон ")]),_c('div',{staticClass:"header-auth__item",class:{ 'header-auth__item_active': _vm.tab === 2 },on:{"click":function($event){_vm.tab = 2}}},[_vm._v(" Email ")])]),(_vm.tab === 1)?_c('div',{staticClass:"header-auth__tab"},[(!_vm.codeSend)?_c('div',[_c('TextField',{attrs:{"type":'tel',"maskString":'+7 (###) ###-##-##',"placeholder":'+7(---) --- -- --',"rules":[
                    _vm.Rules.isRequired(_vm.phoneNumber, 'Некорректный номер'),
                    _vm.Rules.isCorrectPhone(_vm.phoneNumber, 'Некорректный номер') ]},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('div',{staticClass:"header-auth__get-sms button_pink button",on:{"click":function($event){return _vm.validate(_vm.getSmsCode)}}},[_vm._v(" Получить код ")])],1):(!_vm.codeHelp)?_c('div',[_c('div',{staticClass:"header-auth__description"},[_vm._v(" Отправили смс с кодом на номер +7 "+_vm._s(_vm.phoneNumber)+". "),_c('span',{staticClass:"header-auth__description_pink",on:{"click":function($event){_vm.codeSend = false}}},[_vm._v("Изменить номер?")])]),_c('TextField',{staticClass:"mb-4",attrs:{"type":'number',"label":'Код из смс*',"rules":[
                    _vm.Rules.isRequired(_vm.smsCode, 'Неправильный код'),
                    _vm.authSmsConfirmed || 'Неправильный код' ],"successState":_vm.authSmsConfirmed},model:{value:(_vm.smsCode),callback:function ($$v) {_vm.smsCode=$$v},expression:"smsCode"}},[_c('GetCode',{staticClass:"ml-4 ml-xs-0 mt-xs-4",attrs:{"refresh":_vm.authPhoneResend}})],1),_c('div',{staticClass:"header-auth__code-help",on:{"click":function($event){_vm.codeHelp = true}}},[_vm._v(" Не пришел код ")])],1):_c('div',[_c('div',{staticClass:"header-auth__help-caption"},[_vm._v("Что делать")]),_vm._m(0),_c('div',{staticClass:"header-auth__back button button_empty-pink",on:{"click":function($event){_vm.codeHelp = false}}},[_vm._v(" Назад ")])])]):_vm._e(),(_vm.tab === 2)?_c('div',{staticClass:"header-auth__tab"},[_c('TextField',{staticClass:"mb-3",attrs:{"type":'email',"placeholder":'E-mail',"rules":[
                  _vm.Rules.isRequired(_vm.email, 'Нужно заполнить Email адрес'),
                  _vm.Rules.isCorrectEmail(_vm.email, 'Некорректный email') ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('TextField',{staticClass:"pass-block",attrs:{"type":_vm.showPass ? 'text' : 'password',"placeholder":'Пароль',"rules":[_vm.Rules.isRequired(_vm.password, 'Введите пароль')]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"header-auth__buttons"},[_c('div',{staticClass:"header-auth__login button_pink button",on:{"click":function($event){return _vm.validate(_vm.auth, _vm.authData)}}},[_vm._v(" Войти ")]),_c('router-link',{staticClass:"header-auth__forgot-pass",attrs:{"to":{ name: 'RestorePassword' }}},[_vm._v("Забыли пароль?")])],1)],1):_vm._e()])]),_c('RegisterFooter',[_c('div',{staticClass:"register-block"},[_c('div',{staticClass:"register-block__title"},[_vm._v(" Если у вас нет учетной записи: ")]),_c('div',{staticClass:"register-block__button button button_empty-pink",on:{"click":_vm.registerState}},[_vm._v(" Зарегистрироваться ")])]),_c('div',{staticClass:"header-auth__sso mt-8 pt-8 mb-8"},[_c('a',{staticClass:"button_gray button",attrs:{"href":"https://login.microsoftonline.com/af8e89a3-d9ac-422f-ad06-cc4eb4214314/oauth2/v2.0/authorize?client_id=4801abdb-5bbb-415a-b78f-aa34886ffd31&scope=api://4801abdb.astrazeneca.net/user_impersonation&response_type=token"}},[_vm._v(" Вход сотрудникам AZ ")])])])],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ol',{staticClass:"header-auth__help-list"},[_c('li',{staticClass:"header-auth__help-list-item"},[_vm._v(" Возможно, вы ввели не тот номер или ошиблись в цифре. Проверьте всё ещё раз. ")]),_c('li',{staticClass:"header-auth__help-list-item"},[_vm._v(" Проблема может быть со стороны вашего оператора сотовой связи. Обратитесь в службу поддержки. ")])])}]

export { render, staticRenderFns }