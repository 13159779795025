export default {
  state: {
    stream: {},
    password: null
  },
  getters: {
    getStream: s => s.stream, 
    getPassword: s => s.password, 
  },
  mutations: {
    setStream(s, stream) {
      s.stream = stream
    },
    setPassword(s, password) {
      s.password = password
    },
  },
  actions: {
    async fetchStream(ctx, {slug, auth=false, email, name}) {
      return await this.$axios({
        method: "GET",
        url: `/api/streams/facecast/${slug}/`,
      })
        .then(async (response) => {
          const result = response.data;
          if(auth) {
            await ctx.dispatch('fetchStreamPassword', slug)
          } else {
            await ctx.dispatch('fetchStreamPasswordGuest', {slug: slug, email: email, name: name})
          }
          ctx.commit('setStream', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          ctx.dispatch('fetchAlerts', {alerts: 'Произошла ошибка', type: 'error'})
          return false
        });
    },
    async fetchStreamPassword(ctx, slug) {
      return await this.$axios({
        method: "POST",
        url: `/api/streams/facecast/${slug}/auth/`,
      })
        .then(async (response) => {
          const result = response.data.password;
          ctx.commit('setPassword', result)
          return true
        })
        .catch((error) => {
          console.error(error)
          ctx.dispatch('fetchAlerts', {alerts: 'Произошла ошибка', type: 'error'})
          return false
        });
    },
    async fetchStreamPasswordGuest(ctx, {slug, email, name}) {
      let fd = new FormData()
      fd.append('email', email)
      fd.append('name', name)
      return await this.$axios({
        method: "POST",
        url: `/api/streams/facecast/${slug}/guest-auth/`,
        data: fd
      })
        .then(async (response) => {
          const result = response.data.password;
          ctx.commit('setPassword', result)
          return true
        })
        .catch((error) => {
          ctx.dispatch('fetchAlerts', {alerts: 'Произошла ошибка', type: 'error'})
          console.error(error)
          return false
        });
    },
  },
}
